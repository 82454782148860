import { mapState } from 'vuex';
const mapText = {
  monthReport: ['投资者月报', 'Investor Monthly Report', '投資者月報'],
  noContent: ['暂无内容', 'There is currently no content available', '暫無內容']
};
export default {
  name: '',

  data() {
    let yearArr = [];

    for (let i = new Date().getFullYear(); i > new Date().getFullYear() - 10; i--) {
      if (i > 2022) yearArr.push(i);
    }

    return {
      companyInfo: {},
      pastActivity: [],
      yearArr,
      mapText,
      title: this.$api.api_title,
      activity_year: yearArr[0]
    };
  },

  computed: {
    activeMenu() {
      const route = this.$route;
      console.log("route.name" + route.name);
      return route.name;
    },

    ...mapState({
      irMenuData: state => state.irMenuData,
      device: state => state.device,
      language: state => state.language
    })
  },

  created() {
    this.getData();
  },

  mounted() {
    document.oncontextmenu = () => {
      if (this.device == 'mobile') return false;
    };
  },

  methods: {
    getData() {
      this.pastActivity = [];
      this.$http.get(this.$api.monthly_reportList + `?year=${this.activity_year}`).then(res => {
        if (res.code == 0 && res.data) {
          this.pastActivity = [...this.pastActivity, ...res.data];
        }
      });
    },

    change() {
      this.getData();
    },

    viewPdf(link) {
      window.open(link, '_blank');
    }

  }
};